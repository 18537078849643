<template>
  <el-main class="rh-medtable">
    <h1>Formations prégraduées</h1>
    <app-table
      tablenom="medfpregrads"
      :cols="colonnes"
      :items="fpregrads"
      :loadstatus="fpregradsLoadStatus"
      :deletestatus="fpregradDeleteStatus"
      :formroutes="fprFormRoutes"
      :actions="fprActions"
      :ismeddetail="true"
    >
    </app-table>
  </el-main>
</template>
<script>
import AppTable from "@/components/rh/ui/table/AppTable";

export default {
  components: { "app-table": AppTable },
  data() {
    return {
      idMed: this.$route.params.idmed, // récupère le id du médecin dans l'url
      fprFormRoutes: {
        edit: {
          route: "med_fpregrads_edit",
          params: { idmed: "id_med", idfpr: "id" },
        },
        create: {
          route: "med_fpregrads_create",
          params: { idmed: this.$route.params.idmed },
        },
      },
    };
  },
  computed: {
    fpregradsLoadStatus() {
      // 1 = loading, 2 = succès, 3 = échec du chargement des données
      return this.$store.state.fpr.fpregradsLoadStatus;
    },
    fpregradDeleteStatus() {
      // 1 = deleting, 2 = succès, 3 = échec
      return this.$store.state.fpr.fpregradDeleteStatus;
    },
    fpregrads() {
      // n'affiche que les fpregrads du médecin sélectionné
      return this.$store.state.fpr.fpregrads.filter(
        (c) => c.id_med == this.idMed
      );
    },
    colonnes() {
      // n'affiche pas la colonne 'noms des médecins'
      return this.$store.state.fpr.colonnes.filter(
        (col) => col.prop != "medecin"
      );
    },
    fprActions() {
      return this.$store.state.fpr.actions;
    },
  },
};
</script>
